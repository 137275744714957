import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import Popup from 'reactjs-popup';
import {useState, useContext} from 'react';
import {Button} from 'library/components/button';
import {MultiInputField} from 'library/components/multi-input-field';
import {Text} from 'library/components/text';
import {UserContext} from 'source/context/user';
import {CircularLoader} from 'library/components/loader';
import {
  showException,
  showSuccessMessage,
} from 'source/utilities/exceptions/business';
import {DISABLE_PROFILE_CODE_LENGTH} from 'source/utilities/guards/environment';
import {confirmDisableProfile, disableProfile} from './network';

const DeleteButton = styled(Button)`
  width: fit-content;
  margin-left: auto;
`;

const Modal = styled(Popup)`
  &-content {
    width: fit-content;
    padding: 16px 20px;
    border-radius: 6px;
    max-width: 480px;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const StyledCircularLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DeleteProfile = () => {
  const {t} = useTranslation('profile');
  const [user, setUser] = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [hasConfirmationCode, setHasConfirmationCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmCode, setConfirmCode] = useState('');

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleChange = (value: string) => {
    setConfirmCode(value);
  };

  const disableProfileCodeSuccessfullyReceived = () => {
    setHasConfirmationCode(true);
    showSuccessMessage(t('delete_profile.send_code'));
  };

  const handleGetDisableProfileCode = async () => {
    setLoading(true);

    await disableProfile(
      disableProfileCodeSuccessfullyReceived,
      showException,
      setLoading,
    );
  };

  const profileSuccessfullyDisabled = () => {
    handleCloseModal();
    showSuccessMessage(t('delete_profile.successfully_deleted'));
  };

  const handleConfirmDisableProfile = async () => {
    setLoading(true);

    await confirmDisableProfile(
      {code: Number(confirmCode)},
      profileSuccessfullyDisabled,
      showException,
      setLoading,
      setUser,
    );
  };

  return (
    <>
      <DeleteButton buttonType="secondary" onClick={handleOpenModal}>
        {t('delete_profile.delete_button')}
      </DeleteButton>

      <Modal open={openModal} onClose={handleCloseModal}>
        {hasConfirmationCode ? (
          <>
            <StyledText size="M">
              {t('delete_profile.confirm_title', {email: user?.email})}
            </StyledText>
            <MultiInputField
              disabled={loading}
              length={DISABLE_PROFILE_CODE_LENGTH}
              onChange={handleChange}
            />
            <StyledButton
              disabled={
                confirmCode.length < DISABLE_PROFILE_CODE_LENGTH || loading
              }
              onClick={handleConfirmDisableProfile}
              buttonSize="XS"
            >
              {t('delete_profile.send_button')}
            </StyledButton>
          </>
        ) : (
          <>
            <StyledText size="M">{t('delete_profile.modal_title')}</StyledText>
            <ButtonWrapper>
              <StyledButton
                disabled={loading}
                buttonType="secondary"
                onClick={handleCloseModal}
                buttonSize="XS"
              >
                {t('delete_profile.decline_button')}
              </StyledButton>
              <StyledButton
                disabled={loading}
                onClick={handleGetDisableProfileCode}
                buttonSize="XS"
              >
                {t('delete_profile.accept_button')}
              </StyledButton>
            </ButtonWrapper>
          </>
        )}
        {loading && <StyledCircularLoader size={60} />}
      </Modal>
    </>
  );
};
